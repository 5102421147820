import { Capture } from 'app/core/models/capture.model';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { HttpMethods } from 'app/core/enums/http-methods-enum';
import { PendingHttpRequestsService } from 'app/core/services/pending-http-requests.service';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { CaptureStatus } from 'app/core/enums/capture-status.enum';
import { DateUtils } from './date-utils';
import { SpecialistEncounterVerification } from 'app/core/models/specialist-encounter-verification.model';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { ClientReferralVerification } from 'app/core/models/client-referral-verification.model';
import { DrugGroup } from 'app/core/models/drug-group.model';
import { Referral } from 'app/core/models/referral.model';
import { PatientAttachmentStatus } from 'app/core/enums/patient-attachment-status.enum';
import { VerificationType } from 'app/core/enums/verification-type.enum';
import { DateTime, Interval } from 'luxon';
import { EhrReferralVerification } from '../models/ehr-referral-verification.model';
import { AppToastService } from 'app/core/services/app-toast.service';
import { displayCaptureStatus } from '../options/capture-status.opts';

class WrittenDateWithInRangeStatus {
  constructor(public date: Date, public dateWithinRange: boolean) {}
}

const VerificationRequests = [
  {
    methods: [HttpMethods.POST, HttpMethods.PATCH, HttpMethods.DELETE],
    pattern: /patient_prescribers\/\d+\/referral_match_verifications/g,
  },
  {
    methods: [HttpMethods.PATCH],
    pattern: /patient_prescribers\/\d+/g,
  },
  {
    methods: [HttpMethods.PATCH],
    pattern: /captures\/\d+\/set_verifying_patient_attachment/g,
  },
  {
    methods: [HttpMethods.POST, HttpMethods.PATCH],
    pattern: /patient_attachments\/\d+\/patient_match_verifications/g,
  },
  {
    methods: [HttpMethods.POST, HttpMethods.PATCH],
    pattern: /patient_attachments\/\d+\/prescriber_match_verifications/g,
  },
  {
    methods: [HttpMethods.POST, HttpMethods.PATCH],
    pattern: /patient_attachments\/\d+\/specialist_encounter_verifications/g,
  },

  {
    methods: [HttpMethods.POST, HttpMethods.PATCH],
    pattern: /patient_attachments\/\d+\/drug_match_verifications/g,
  },
];

const anyVerificationRequestsPending = (pendingHttpRequestsService: PendingHttpRequestsService) =>
  VerificationRequests.some(vr => vr.methods.some(method => pendingHttpRequestsService.isPending(method, vr.pattern)));

const isVerificationFrozen = (authService: AuthenticationService, capture: Capture, verification = null) => {
  if (authService.isCaptureAdminUser) {
    return (capture && capture.frozen) || (verification && verification.frozen);
  } else {
    return true;
  }
};

const isPatientAttachmentVerificationFrozen = (
  authService: AuthenticationService,
  capture: Capture,
  patientAttachment: PatientAttachment,
  verification
) => {
  if (!isVerificationFrozen(authService, capture, verification)) {
    return patientAttachment && patientAttachment.markedAsInvalid;
  } else {
    return true;
  }
};

const activeConsultNoteStatuses = [PatientAttachmentStatus.open];

const isConsultNoteSubmittable = (patientAttachment: PatientAttachment) => {
  if (activeConsultNoteStatuses.some(status => status === patientAttachment.status)) {
    return patientAttachment.completeForSubmit;
  } else {
    return false;
  }
};

const isVerificationTypeFrozen = (patientAttachment: PatientAttachment, verificationType: VerificationType) => {
  const status = patientAttachment && patientAttachment.status;

  switch (status) {
    case PatientAttachmentStatus.closed:
      return verificationType !== VerificationType.prescriberMatch;
  }

  return false;
};

const isConsultNoteVerificationFrozen = (
  authService: AuthenticationService,
  patientAttachment: PatientAttachment,
  verificationType: VerificationType,
  verification = null
) => {
  if (patientAttachment?.markedAsInvalid) {
    return true;
  }

  if (isVerificationTypeFrozen(patientAttachment, verificationType)) {
    return true;
  }

  return isVerificationFrozen(authService, null, verification);
};

const isEncounterDateWithinRange = (encounterDate: string, writtenDate: Date, timeframe: number) => {
  // Wrap everything in new Date because type checking doesn't run during
  // actual runtime and sometimes these are not a string or not a date
  const encounterDateTime = DateTime.fromJSDate(new Date(encounterDate));
  const writtenDateTime = DateTime.fromJSDate(new Date(writtenDate));
  const startWindow = writtenDateTime.minus({ months: timeframe });
  const endWindow = writtenDateTime.plus({ months: timeframe });
  return Interval.fromDateTimes(startWindow, endWindow).contains(encounterDateTime);
};

const isAnyEncounterDateInRange =
  (encounterDates: string[], writtenDate: Date, timeframe: number) =>
    encounterDates.some(encounterDate =>
      isEncounterDateWithinRange(encounterDate, writtenDate, timeframe)
    );

const checkWrittenDatesAgainstEncounterDates =
  (writtenDates: Date[], encounterDates: string[], timeframe: number) => {
    const presentEncounterDates = encounterDates.filter(Boolean);
    const anyEncounterDates = !!presentEncounterDates.length;

    return writtenDates.map(writtenDate => {
      const dateWithinRange = anyEncounterDates ?
        isAnyEncounterDateInRange(presentEncounterDates, writtenDate, timeframe) : null

      return new WrittenDateWithInRangeStatus(writtenDate, dateWithinRange);
    });
  }

const earliestReferralMatchVerification = (verifications: ReferralMatchVerification[]) => verifications.sort((a, b) => {
  if (a.date < b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return 1;
  }
  return 0;
})[0];

const standardReferralMatchVerificationHasSelection = (rmv: ReferralMatchVerification) =>
  rmv.provider || rmv.specialty || rmv.office;

const referralMatchVerificationHasSelection = (rmv: ReferralMatchVerification) =>
  standardReferralMatchVerificationHasSelection(rmv) ||
  !!rmv.ehrReferralVerificationId ||
  ((rmv.client || rmv.peerClientReferralVerification) && DateUtils.isValid(rmv.clientReferralDate))

const getAllSelectedReferralMatchVerifications = (patientPrescriber: PatientPrescriber) =>
  patientPrescriber.referralMatchVerifications.filter(referralMatchVerificationHasSelection);

const isOnlyReferralMatchSelection = (rmv: ReferralMatchVerification, allSelectedRmvs: ReferralMatchVerification[]) => {
  if (allSelectedRmvs.length === 1 && allSelectedRmvs[0].id === rmv.id) {
    return referralMatchVerificationHasSelection(rmv);
  } else {
    return false;
  }
};

const isReferralMatchVerificationDisabled = (
  rmv: ReferralMatchVerification,
  capture: Capture,
  allSelectedRmvs: ReferralMatchVerification[],
  authService: AuthenticationService
) => {
  const rmvFrozen = isVerificationFrozen(authService, capture, rmv);

  if (!rmvFrozen) {
    if (capture.status === CaptureStatus.needsEvidence || capture.status === CaptureStatus.pending) {
      return isOnlyReferralMatchSelection(rmv, allSelectedRmvs);
    } else {
      return false;
    }
  }

  return true;
};

const isSpecialistEncounterDateWithinRange = (verification: SpecialistEncounterVerification, capture: Capture) => {
  const formattedEncounterDate = DateUtils.toServerFormat(verification.encounterDate);
  return isEncounterDateWithinRange(
    formattedEncounterDate,
    DateTime.fromISO(capture.candidate.writtenDate).toJSDate(),
    capture.client.specialistEncounterTimeframeInMonths
  );
};

const findDmvByNdc = (ndc: string, patientAttachment: PatientAttachment) => {
  if (ndc) {
    return patientAttachment.drugMatchVerifications.find(dmv => dmv.ndc === ndc);
  }

  return null;
};

const findDmvByDrugGroup = (drugGroup: DrugGroup, patientAttachment: PatientAttachment) => {
  if (drugGroup) {
    return patientAttachment.drugMatchVerifications.find(dmv => dmv.drugGroupId === drugGroup.id);
  }

  return null;
};

const findDmvByReferenceNumber = (referenceNumber: string, patientAttachment: PatientAttachment) => {
  if (referenceNumber) {
    return patientAttachment.drugMatchVerifications.find(dmv => dmv.referenceNumber === referenceNumber);
  }

  return null;
};

const resolveReferralMatchVerification = (referral: Referral, verificationsByReferralId: object) => {
  if (!(referral.id in verificationsByReferralId)) {
    const verification = new ReferralMatchVerification();
    verification.referral = referral;
    return verification;
  } else {
    return verificationsByReferralId[referral.id];
  }
};

const resolveClientVerification = (clientReferralVerification: ClientReferralVerification,
  rmvsByCrvId: { [rmvId: number]: ReferralMatchVerification }) => {
  if (clientReferralVerification.id in rmvsByCrvId) {
    return rmvsByCrvId[clientReferralVerification.id];
  } else {
    const verification = new ReferralMatchVerification();
    verification.clientReferralDate = clientReferralVerification.referralDate;
    verification.clientReferralVerification = clientReferralVerification;
    return verification;
  }
};

const resolveEhrVerification = (ehrReferralVerification: EhrReferralVerification,
  rmvsByErvId: { [ervId: number]: ReferralMatchVerification }) => {
  if (ehrReferralVerification.id in rmvsByErvId) {
    return rmvsByErvId[ehrReferralVerification.id];
  } else {
    const verification = new ReferralMatchVerification();
    verification.ehrReferralVerification = ehrReferralVerification;
    return verification;
  }
};

interface DrugMatchVerificationResolutionParams {
  drugMatchVerificationId?: number;
  ndc: string;
  referenceNumber?: string;
  drugGroup: DrugGroup;
}

const resolveMostRelevantDrugMatchVerification = (
  params: DrugMatchVerificationResolutionParams,
  patientAttachment: PatientAttachment
) => {
  if (params.drugMatchVerificationId) {
    return patientAttachment.drugMatchVerifications.find(dmv => dmv.id === params.drugMatchVerificationId);
  }

  return (
    findDmvByNdc(params.ndc, patientAttachment) ||
    findDmvByReferenceNumber(params.referenceNumber, patientAttachment) ||
    findDmvByDrugGroup(params.drugGroup, patientAttachment)
  );
};

const resolveMostRelevantDrugMatchVerificationFromCapture = (
  capture: Capture,
  patientAttachment: PatientAttachment
) => resolveMostRelevantDrugMatchVerification(
  {
    drugMatchVerificationId: capture.drugMatchVerificationId,
    ndc: capture.candidate.ndc,
    referenceNumber: capture.candidate.referenceNumber,
    drugGroup: capture.candidate.drugGroup,
  },
  patientAttachment
);

const showCaptureTransitionedToast = (
  toastService: AppToastService,
  newStatus: string
) => {
  toastService.show(`Capture has been transitioned to ${displayCaptureStatus(newStatus)}`, {
    cssClass: 'bg-success-subtle',
  });
}

const clientBasedReferralMatchVerification = (rmv: ReferralMatchVerification) =>
  rmv.client || rmv.peerClientReferralVerification;

const isStandardReferralMatchVerification = (rmv: ReferralMatchVerification) =>
  !!rmv.referral;

const isSelectedStandardReferralMatchVerification = (rmv: ReferralMatchVerification) =>
  isStandardReferralMatchVerification(rmv) &&
  standardReferralMatchVerificationHasSelection(rmv);

const isClientReferralMatchVerification = (rmv: ReferralMatchVerification) =>
  clientBasedReferralMatchVerification(rmv) &&
  rmv.hasClientReferralTaskSubmission;

const isClientDateReferralMatchVerification = (rmv: ReferralMatchVerification) =>
  clientBasedReferralMatchVerification(rmv) &&
  !rmv.hasClientReferralTaskSubmission

const isEhrReferralMatchVerification = (rmv: ReferralMatchVerification) =>
  !!rmv.ehrReferralVerificationId;

const sortReferralMatchVerificationsByClientReferralDate = (rmvs: ReferralMatchVerification[]) =>
  rmvs.sort((a, b) => new Date(a.clientReferralDate).getTime() - new Date(b.clientReferralDate).getTime());

export {
  WrittenDateWithInRangeStatus,
  isVerificationFrozen,
  isEncounterDateWithinRange,
  checkWrittenDatesAgainstEncounterDates,
  standardReferralMatchVerificationHasSelection,
  earliestReferralMatchVerification,
  getAllSelectedReferralMatchVerifications,
  isReferralMatchVerificationDisabled,
  anyVerificationRequestsPending,
  isSpecialistEncounterDateWithinRange,
  isPatientAttachmentVerificationFrozen,
  resolveMostRelevantDrugMatchVerification,
  resolveMostRelevantDrugMatchVerificationFromCapture,
  isConsultNoteSubmittable,
  isConsultNoteVerificationFrozen,
  resolveReferralMatchVerification,
  resolveClientVerification,
  resolveEhrVerification,
  showCaptureTransitionedToast,
  sortReferralMatchVerificationsByClientReferralDate,
  isStandardReferralMatchVerification,
  isSelectedStandardReferralMatchVerification,
  isClientReferralMatchVerification,
  isClientDateReferralMatchVerification,
  isEhrReferralMatchVerification,
};
